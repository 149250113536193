import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

export const Navigation = () => {
    return (
        <Fragment>
            <ul className="nav">
                <li className="nav-item">
                    <NavLink className="link" to='/' exact>Home </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="link" to='/showroom'>Showroom </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="link" to='/future'>Future </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="link" to='/2019'>In 2019 </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="link" to='/2020'>In 2020 </NavLink>
                </li>
            </ul>
        </Fragment>
    )
}

