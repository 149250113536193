import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

//import { Home } from './pages/Home'
//import { Showroom } from './pages/Showroom'
//import { Future } from './pages/Future'
//import { In2019 } from './pages/In2019'
//import  In2020  from './pages/In2020'
import { Navigation } from './components/Navigation'

const Home = lazy(() => import('./pages/Home'))
const Future = lazy(() => import('./pages/Future'))
const Showroom = lazy(() => import('./pages/Showroom'))
const In2019 = lazy(() => import('./pages/In2019'))
const In2020 = lazy(() => import('./pages/In2020'))

const App = () => {
  return (
    <BrowserRouter>
      <Navigation />
      <div className='container__inner'>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path={'/'} exact component={Home} />
            <Route path={'/showroom'} component={Showroom} />
            <Route path={'/future'} component={Future} />
            <Route path={'/2019'} component={In2019} />
            <Route path={'/2020'} component={In2020} />
          </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  )
}

export default App;

